import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      // var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p>{education.description}</p></div>
      })
      // var work = this.props.data.work.map(function(work){
      //   return <div key={work.company}><h3>{work.company}</h3>
      //       <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
      //       <p>{work.description}</p>
      //   </div>
      // })
      var skills = this.props.data.skills.map(function(skills){
        var skillImage = 'images/team/'+skills.image;
        return <div key={skills.title} className="columns portfolio-item">
           <div className="item-wrap">
               <img alt={skills.title} src={skillImage} />
                 <h5>{skills.title}</h5>
          </div>
        </div>
      })
    }

    return (
      <section id="resume">

      <div className="row education">

      <div className="twelve columns header-col text-center">
        <h1><span>Skills</span></h1>
      </div>
      <br></br>
      <br></br>
      <div className="twelve columns collapsed text-center">

        <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
            {skills}
        </div>
      </div>
      </div>

      <div className="row skill">
         <div className="twelve columns header-col text-center">
            <h1><span>Education</span></h1>
         </div>
          <br></br>
          <br></br>
         {/* <div className="nine columns main-col"> */}
            <div className="row item">
               <div className="twelve columns text-center">
                 {education}
               </div>
            </div>
         {/* </div> */}
      </div>


      {/* <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div> */}
   </section>
    )
  }
}

export default Resume;
